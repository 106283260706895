import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image";

const Logo = () => {
  const data = useStaticQuery(graphql`{
  placeholderImage: file(relativePath: {eq: "logo_black@2x.png"}) {
    childImageSharp {
      gatsbyImageData(width: 960, layout: CONSTRAINED)
    }
  }
}
`)

  return (
    <div className="logo">
      <GatsbyImage alt="logo" image={data.placeholderImage.childImageSharp.gatsbyImageData} />
    </div>
  );
}

export default Logo
