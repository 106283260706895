import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image";

import "react-responsive-carousel/lib/styles/carousel.min.css"
import { Carousel } from "react-responsive-carousel"

const styles = {
  shadow: {
    boxShadow: `
                  0 2.8px 2.2px rgba(0, 0, 0, 0.02),
                  0 6.7px 5.3px rgba(0, 0, 0, 0.028),
                  0 12.5px 10px rgba(0, 0, 0, 0.035),
                  0 22.3px 17.9px rgba(0, 0, 0, 0.042),
                  0 41.8px 33.4px rgba(0, 0, 0, 0.05),
                  0 100px 80px rgba(0, 0, 0, 0.07)
                  `,
  },
  mainDiv: {
    width: "80%",
    // height: "50vh",
  },
  imgDiv: {height: "50vh"},
  imgStyle: { height: "50vh" },
}

const CarouselComponent = () => {
  const data = useStaticQuery(graphql`{
  eka: file(relativePath: {eq: "karusellikuvat/tuotteet-1.jpeg"}) {
    childImageSharp {
      gatsbyImageData(quality: 90, width: 960, layout: CONSTRAINED)
    }
  }
  toka: file(relativePath: {eq: "karusellikuvat/kaksi-tuolia.jpeg"}) {
    childImageSharp {
      gatsbyImageData(quality: 90, width: 960, layout: CONSTRAINED)
    }
  }
  kolmas: file(relativePath: {eq: "karusellikuvat/tuotteet-2.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 90, width: 960, layout: CONSTRAINED)
    }
  }
}
`)

  return (
    <div style={(styles.shadow, styles.mainDiv)}>
      <Carousel
      showIndicators={true}
        autoPlay={true}
        infiniteLoop={true}
        showThumbs={false}
        showStatus={false}
        stopOnHover={false}
        transitionTime="1000"
        // dynamicHeight={true}
        interval="3000"
      >
        <div style={styles.imgDiv}>
          <GatsbyImage alt="kuva hiustuotteista" image={data.eka.childImageSharp.gatsbyImageData} style={styles.imgStyle} />
        </div>
        <div style={styles.imgDiv}>
          <GatsbyImage
            alt="toinen kuva hiustuotteista"
            image={data.kolmas.childImageSharp.gatsbyImageData}
            style={styles.imgStyle} />
        </div>
      </Carousel>
    </div>
  );
}

export default CarouselComponent
