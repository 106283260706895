import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import VaraaAikaButton from "./varaa-aika-button"
// import BackgroundImage from "gatsby-background-image"
import { getImage } from "gatsby-plugin-image"
import {BgImage} from "gbimage-bridge"

const VaraaAika = () => {
  const data = useStaticQuery(graphql`{
  eka: file(relativePath: {eq: "jenna-varjaa.jpeg"}) {
    childImageSharp {
      gatsbyImageData(quality: 90, width: 960, layout: CONSTRAINED)
    }
  }
}
`)

const pluginImage = getImage(data.eka)
  return (
    <BgImage
      tag="section"
      image={pluginImage}
      style={{
        height: "40vh",
        // padding: "60px",
        backgroundPosition: "center 20%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        boxShadow: `
                  0 2.8px 2.2px rgba(0, 0, 0, 0.02),
                  0 6.7px 5.3px rgba(0, 0, 0, 0.028),
                  0 12.5px 10px rgba(0, 0, 0, 0.035),
                  0 22.3px 17.9px rgba(0, 0, 0, 0.042),
                  0 41.8px 33.4px rgba(0, 0, 0, 0.05),
                  0 100px 80px rgba(0, 0, 0, 0.07)
                  `,
      }}
    >
      <VaraaAikaButton />
    </BgImage>
    // <BackgroundImage
    //   tag="section"
    //   fluid={data.eka.childImageSharp.gatsbyImageData}
    //   style={{
    //     height: "40vh",
    //     // padding: "60px",
    //     backgroundPosition: "center 20%",
    //     display: "flex",
    //     alignItems: "center",
    //     justifyContent: "center",
    //     boxShadow: `
    //               0 2.8px 2.2px rgba(0, 0, 0, 0.02),
    //               0 6.7px 5.3px rgba(0, 0, 0, 0.028),
    //               0 12.5px 10px rgba(0, 0, 0, 0.035),
    //               0 22.3px 17.9px rgba(0, 0, 0, 0.042),
    //               0 41.8px 33.4px rgba(0, 0, 0, 0.05),
    //               0 100px 80px rgba(0, 0, 0, 0.07)
    //               `,
    //   }}
    // >
    //   <VaraaAikaButton />
    // </BackgroundImage>
  );
}

export default VaraaAika
