import React from "react"

const Yhteystiedot = () => {
  return (
    <div
      style={{
        // background: "rgba(227, 246, 245, 0.9)",
        // background: "rgba(229, 229, 229, 0.9)",
        // background: "#1e1b19",
        // paddingTop: "2em",
        paddingBottom: "6em",
        margin: "0 auto",
        // marginTop: "1vh",
      }}
    >
      <div className="p-spacing">
        <h3>Avoinna</h3>
        <p>Ma - Pe 10-17</p>
        <p>Muuten sopimuksen mukaan.</p>
      </div>
      <div
        style={{
          // backgroundColor: "#dcd1c2f0",
          // background: "#e3f6f5",
          paddingTop: "30px",
          // paddingBottom: "10vh",
          margin: "0 auto",
          // marginTop: "30px",
          maxWidth: "960px",
        }}
      >
        <h3>Ota yhteyttä</h3>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(auto-fit, minmax(250px, 1fr))",
            // gridColumnGap: "2em",
            gridRowGap: "1em",
            gridTemplateRows: "1fr",
          }}
        >
          <div
            style={{
              background: "#ffffff",
              paddingTop: "1em",
            }}
          >
            Yrjönkatu 7
            <br />
            28100 Pori
          </div>

          <div
            style={{
              background: "#ffffff",
              paddingTop: "1em",
            }}
          >
            040-5443433
            <br />
            jenna@kold.fi
          </div>
        </div>
      </div>

      <div style={{ paddingTop: "2em" }}>Y-tunnus 3149289-6</div>
    </div>
  )
}

export default Yhteystiedot
