import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

import Logo from "../components/logo"
import VaraaAika from "../components/varaa-aika"
import Yhteystiedot from "../components/yhteystiedot"
import CarouselComponent from "../components/carousel-component"
import Esittelyteksti from "../components/esittelyteksti"
import HairPicturesComponent from "../components/hairpictures-component"
import SomeComponent from "../components/some-component"
import VerkkokauppaButton from "../components/verkkokauppa-button"

const IndexPage = () => {
  return (
    <Layout>
      <Seo title="Home" />

      <div
        style={{
          height: "2em",
        }}
      ></div>
      <Logo />

      <div style={{ margin: "0 1em", marginTop: "2.5em" }}>
        <VerkkokauppaButton />
      </div>

      <div style={{ margin: "0 1em", marginTop: "2.5em" }}>
        <VaraaAika />
      </div>

      <div
        style={{
          height: "2em",
        }}
      ></div>

      <div style={{ margin: "5em 1em" }}>
        <Esittelyteksti />
      </div>

      <div
        style={{
          height: "2em",
        }}
      ></div>

      <div
        style={{
          margin: "0 1em",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <CarouselComponent />
      </div>

      <div
        style={{
          height: "2em",
        }}
      ></div>

      <div style={{ margin: "0 1em" }}>
        <HairPicturesComponent />
      </div>

      <Yhteystiedot />

      <SomeComponent />
    </Layout>
  )
}

export default IndexPage
