import React from "react"

const VaraaAikaButton = () => {
  return (
    <a
      href="https://booksalon.fi/kold"
      target="_blank"
      rel="noopener noreferrer"
      className="varaaButtonStyle"
    >
      Ajanvaraus ja hinnasto
    </a>
  )
}

export default VaraaAikaButton
