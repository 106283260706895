import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image";

const styles = {
  shadow: {
    boxShadow: `
                  0 2.8px 2.2px rgba(0, 0, 0, 0.02),
                  0 6.7px 5.3px rgba(0, 0, 0, 0.028),
                  0 12.5px 10px rgba(0, 0, 0, 0.035),
                  0 22.3px 17.9px rgba(0, 0, 0, 0.042),
                  0 41.8px 33.4px rgba(0, 0, 0, 0.05),
                  0 100px 80px rgba(0, 0, 0, 0.07)
                  `,
  },
  imagesDiv: {
    maxWidth: "80%",
    margin: "0 auto",
    display: "grid",
    gridTemplateColumns: "repeat(auto-fit, minmax(200px, 35vw))",
    // gridTemplateColumns: "repeat(auto-fit, minmax(200px, 1fr))",
    gridColumnGap: "2.5em",
    gridRowGap: "1em",
    justifyContent: "center",
  },
}

const HairPicturesComponent = () => {
  const data = useStaticQuery(graphql`{
  eka: file(relativePath: {eq: "hiuskuvat/hiukset-1.jpeg"}) {
    childImageSharp {
      gatsbyImageData(quality: 90, width: 960, layout: CONSTRAINED)
    }
  }
  toka: file(relativePath: {eq: "hiuskuvat/hiukset-2.jpeg"}) {
    childImageSharp {
      gatsbyImageData(quality: 90, width: 960, layout: CONSTRAINED)
    }
  }
  kolmas: file(relativePath: {eq: "hiuskuvat/hiukset-3.jpeg"}) {
    childImageSharp {
      gatsbyImageData(quality: 90, width: 960, layout: CONSTRAINED)
    }
  }
}
`)
  return (
    <div
      style={{
        margin: "0 auto",
        marginTop: "2.5em",
      }}
    >
      <div style={styles.imagesDiv}>
        <div>
          <GatsbyImage alt="kuva kampauksesta" image={data.eka.childImageSharp.gatsbyImageData} style={styles.shadow} />
        </div>
        <div>
          <GatsbyImage alt="toinen kuva kampauksesta" image={data.kolmas.childImageSharp.gatsbyImageData} style={styles.shadow} />
        </div>
      </div>
      <a
        href="https://instagram.com/koldjenna"
        target="_blank"
        rel="noopener noreferrer"
        className="instaButtonStyle"
      >
        Katso lisää töitämme
      </a>
    </div>
  );
}

export default HairPicturesComponent
