import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faInstagram,
  faTiktok,
  faFacebookSquare,
} from "@fortawesome/free-brands-svg-icons"

const styles = {
  icon: {
    color: "#af8664",
  },
  div: {
    display: "flex",
    justifyContent: "center",
  },
  link: {
    marginLeft: "1em",
  },
}
const SomeComponent = () => {
  return (
    <div style={styles.div}>
      <a
        style={styles.link}
        href="https://instagram.com/koldjenna"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FontAwesomeIcon icon={faInstagram} style={styles.icon} size="2x" />
      </a>
      <a
        style={styles.link}
        href="https://www.tiktok.com/@koldjenna"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FontAwesomeIcon icon={faTiktok} style={styles.icon} size="2x" />
      </a>
      <a
        style={styles.link}
        href="https://facebook.com/koldjenna"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FontAwesomeIcon
          icon={faFacebookSquare}
          style={styles.icon}
          size="2x"
        />
      </a>
    </div>
  )
}

export default SomeComponent
