import React from "react"

const EsittelyTeksti = () => {
  return (
    <div
      className="esittelyteksti"
      style={{
        margin: "0 auto",
        maxWidth: "45em",
      }}
    >
      <div className="p-spacing">
        <p>
          Kold on moderni ja rento hiusten hemmottelupaikka Porin ytimessä
          Yrjönkadulla.
        </p>
      </div>

      <div className="p-spacing">
        <p>
          Meille on erityisen tärkeää pitää ammattitaitomme huipputasolla, sekä
          pysyä mukana uusimmissa trendeissä ja tekniikoissa. Tämän takaamme
          jatkuvalla kouluttautumisella alamme huippunimien kanssa, niin
          Suomessa kuin ulkomaillakin.
        </p>
      </div>

      <div className="p-spacing">
        <p>
          Kold:ssa värjäämme suomalaisella Four Reasons Luxima ja
          hajusteettomalla Optima värisarjoilla. Muotoilutuotteista edustettuina
          on Kevin Murphy, Maria Nila sekä Four Reasons.
        </p>
      </div>
    </div>
  )
}

export default EsittelyTeksti
