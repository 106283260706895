import React from "react"

const VerkkokauppaButton = () => {
  return (
    <a
      href="https://shop.kold.fi"
      target="_blank"
      rel="noopener noreferrer"
      className="varaaButtonStyle kauppaButtonStyle"
    >
      Tästä verkkokauppaan
    </a>
  )
}

export default VerkkokauppaButton
